define("lh-public-olo/components/category-tabs", ["exports", "@ember/runloop", "@ember/component", "jquery", "lh-public-olo/mixins/header-child", "@ember/service", "@ember/object"], function (_exports, _runloop, _component, _jquery, _headerChild, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_headerChild.default, {
    ticket: (0, _service.inject)(),
    didInsertElement: function didInsertElement() {
      this.setActive(this.get('categories.firstObject'));

      this._super.apply(this, arguments); // to remove this listener later on I need to have a reference to the callback
      // I'm setting it here to bind this to the mixin/component and not to window
      // using Ember.run.bind means that this callback executes within the runloop


      this.set('_onResize', (0, _runloop.bind)(this, function () {
        if (this.get('media.isMobile')) {
          this.headerHide();
        } else {
          this.headerReset();
        }
      })); // when the window changes sizes make sure the header still makes sense

      window.addEventListener('resize', this._onResize);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.headerReset(); // remove resize listener so it doesn't leak to other routes

      window.removeEventListener('resize', this._onResize);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      // when showDropdown changes we want to make sure
      // the correct category is active
      (0, _runloop.schedule)('afterRender', this, function () {
        this.setActive(this.category, true);
      });
    },
    onScroll: function onScroll() {
      if (this.get('media.isMobile')) {
        this.headerHide();
      }

      this.setActive();
    },
    showOffHoursWarning: (0, _object.computed)('ticket.dineIn', 'ticket.menuSchedule', function () {
      return this.get('ticket.dineIn') && this.get('ticket.menuSchedule.duringOffHours');
    }),
    storeClosureMessage: (0, _object.computed)('ticket.activeStoreClosureMessage', 'ticket.isStoreClosureActive', function () {
      if (this.get('ticket.isStoreClosureActive') && this.get('ticket.activeStoreClosureMessage')) {
        return this.get('ticket.activeStoreClosureMessage');
      }
    }),

    /**
     * Get the active category based on scroll position
     *
     * @method     getActive
     * @returns    {Object} Model instance of the category currently active
     */
    getActive: function getActive() {
      // TODO: consider caching offsets for performance reasons
      var categories = this.categories.toArray();
      var scrollContainer = this.get('media.isMobile') ? 'html' : document.querySelector('.menu-column');

      for (var x = 0; x < categories.length; x++) {
        var category = categories[x];
        var grid = (0, _jquery.default)("#".concat(category.get('id'), " + .ui.grid"))[0]; // we're getting the bottom of the grid here
        // subtract 20px for padding reasons

        var offset = grid.offsetTop + grid.offsetHeight - 20;

        if ((0, _jquery.default)(scrollContainer).scrollTop() < offset) {
          return category;
        }
      }
    },

    /**
     * Set active state for specific category's tab
     *
     * @method     setActive
     * @param      {Object} category Model instance of the category to be considered as active
     * @param      {Boolean} force Whether or not to ignore category check
     */
    setActive: function setActive() {
      var category = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getActive();
      var force = arguments.length > 1 ? arguments[1] : undefined;

      if (category !== this.category || force) {
        // this whole function feels very anti "the ember way"
        // but running this inside of the runloop doesn't work
        var $tab = this.$(".item[data-value=\"".concat(category.get('id'), "\"]")); // scroll to tab

        if (!this.showDropdown) {
          var $menu = this.$('.secondary.pointing.menu');
          $menu.stop().animate({
            scrollLeft: $tab[0].offsetLeft
          }, 100);
        }

        var activeClass = 'active';

        if (this.showDropdown) {
          activeClass = 'active selected';
          this.$('.ui.dropdown.categories').dropdown('set text', category.get('name'));
        }

        this.$('.item').removeClass(activeClass);
        $tab.addClass(activeClass);
        this.set('category', category);
      }
    },
    actions: {
      categorySelect: function categorySelect(guid) {
        var _document$getElementB = document.getElementById(guid),
            offsetTop = _document$getElementB.offsetTop;

        var scrollContainer = this.get('media.isMobile') ? 'html' : document.querySelector('.menu-column');
        (0, _jquery.default)(scrollContainer).animate({
          scrollTop: offsetTop - 20
        }, 350);
      },
      menuScheduleSelect: function menuScheduleSelect(ref) {
        if (this.get('ticket.menuSchedule.id') !== ref) {
          if (this.get('ticket.items').length) {
            confirm('Changing menus will clear the items from your cart. Do you want to proceed?') && this.ticket.clearCart() && this.sendAction('setMenuSchedule', ref);
          } else {
            this.sendAction('setMenuSchedule', ref);
          }
        }
      }
    }
  });

  _exports.default = _default;
});