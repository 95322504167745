define("lh-public-olo/models/drop-off-address", ["exports", "@ember-data/model", "lh-public-olo/enums/address-types", "ember-cp-validations", "@ember/object", "lh-public-olo/models/delivery-address"], function (_exports, _model, _addressTypes, _emberCpValidations, _object, _deliveryAddress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    addressLine1: null,
    addressLine2: {
      description: (0, _object.computed)('model.lastMileLabel', function () {
        return this.get('model.lastMileLabel');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: (0, _object.computed)('model.ticket.orderType.requiresAddress', 'model.lastMileLabel', function () {
          if (this.get('model.ticket.orderType.requiresAddress') && this.get('model.lastMileLabel')) {
            return false;
          }

          return true;
        })
      }), (0, _emberCpValidations.validator)('length', {
        max: 68
      })]
    },
    city: null,
    state: null,
    zip: null
  });

  var _default = _deliveryAddress.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    lastMileLabel: (0, _model.attr)('string'),
    lastMileInstructions: (0, _model.attr)('string'),
    addressType: _addressTypes.default.DROP_OFF
  });

  _exports.default = _default;
});