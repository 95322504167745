define("lh-public-olo/routes/location", ["exports", "@ember/service", "@ember/routing/route", "jquery", "lh-public-olo/utils/get-local-storage", "lh-public-olo/utils/clear-local-storage", "rsvp"], function (_exports, _service, _route, _jquery, _getLocalStorage2, _clearLocalStorage, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    queryParams: {
      referralSource: {
        refreshModel: true
      },
      referralGuid: {
        refreshModel: true
      }
    },
    ticket: (0, _service.inject)(),
    session: (0, _service.inject)(),
    router: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    analytics: (0, _service.inject)(),
    isStandaloneRouteTransition: false,
    getSchedules: function getSchedules(location) {
      var _this = this;

      var filterSchedules = this.get('router.currentRoute.queryParams.schedules') || [];

      if (!this.get('ticket.isDeferredFeatureEnabled') || !this.get('ticket.isDeferred')) {
        if (filterSchedules.length > 0) {
          return (0, _rsvp.resolve)(location.get('menuSchedules').then(function (schedules) {
            return schedules.filter(function (schedule) {
              return filterSchedules.includes(schedule.id);
            });
          }));
        }

        return (0, _rsvp.resolve)(location.get('menuSchedules'));
      }

      return this.ticket.getOrderTime().then(function (orderTimeUtc) {
        return _this.store.query('available-menu-schedule', {
          orderType: _this.get('ticket.orderTypeGuid'),
          time: orderTimeUtc
        });
      });
    },
    serialize: function serialize(model) {
      return {
        ref: model.get('id')
      };
    },
    getActiveStoreClosures: function getActiveStoreClosures(location) {
      return this.ticket.getAvailableTimeSlots(location.get('orderTypes.firstObject')).then(function (response) {
        if (response.storeClosures.length) {
          return {
            isStoreClosureActive: true,
            activeStoreClosureMessage: response.storeClosures[0].message
          };
        }

        return {};
      }).catch(function () {
        return (0, _rsvp.resolve)({});
      });
    },
    beforeModel: function beforeModel(transition) {
      this.ticket.setProperties({
        locationRef: undefined,
        tenderTypeGuid: undefined,
        orderTypeGuid: undefined
      });

      if (!transition.isCausedByInitialTransition) {
        this.store.unloadAll();
      }

      this.set('ticket.items', []);

      if (transition.targetName.includes('update-password') || transition.targetName.includes('confirmation') && transition.to.queryParams.orderRef) {
        this.set('isStandaloneRouteTransition', true);
      }
    },
    model: function model(params) {
      return this.store.findRecord('location', params.ref);
    },
    afterModel: function afterModel(model, transition) {
      var _this2 = this;

      var _transition$to$queryP = transition.to.queryParams,
          referralGuid = _transition$to$queryP.referralGuid,
          referralSource = _transition$to$queryP.referralSource;

      if (referralGuid !== undefined || referralSource !== undefined) {
        this.ticket.setProperties({
          referralGuid: referralGuid,
          referralSource: referralSource
        });
        return this.replaceWith('location', model.get('id'), {
          queryParams: {
            referralGuid: undefined,
            referralSource: undefined
          }
        });
      }

      document.title = "".concat(model.get('name'), " - SkyTab Online");
      this.ticket.setProperties({
        locationRef: model.get('id'),
        timeZoneOffset: model.get('timeZoneOffset'),
        tenders: model.get('tenders'),
        tipSettings: model.get('tipSettings'),
        preselectedTip: model.get('tipSettings.preselectedTip'),
        selectedTip: model.get('tipSettings.preselectedTip'),
        tipPercentages: model.get('tipPercentages'),
        dineInAllowDuringOffHours: model.get('dineInAllowDuringOffHours'),
        signInPromptEnabled: model.get('signInPromptEnabled'),
        dropOffAddresses: model.get('dropOffAddresses'),
        deliverToAddresses: model.get('deliverToAddresses')
      });
      this.analytics.initAnalytics(model.get('metaPixelId')); // If the store is closed return without processing the local storage

      if (model.get('isClosed')) {
        return;
      }

      if (this.get('session.isAuthenticated')) {
        this.customerService.getCustomer();
      }

      this.set('customerService.countryCode', model.get('countryCode')); // ticket.locationRef must be set before validation

      this.ticket.validateStorage();

      if (this.get('ticket.dineIn')) {
        this.ticket.setOrderSetting('orderTypeGuid', model.get('orderTypes.firstObject.id'));
        this.set('ticket.orderType', model.get('orderTypes.firstObject'));
        this.ticket.setOrderSetting('tableNotes', this.get('ticket.tableNotes'));
      }

      if (this.get('ticket.referralGuid') || this.get('ticket.referralSource')) {
        this.ticket.setOrderSetting('referralGuid', this.get('ticket.referralGuid'));
        this.ticket.setOrderSetting('referralSource', this.get('ticket.referralSource'));
      } // get cart-items out of localStorage


      return this.store.query('cart-item', {
        location: model.get('id')
      }).then(function (cartItems) {
        _this2.set('ticket.items', cartItems.toArray()); // get orderSettings from localStorage


        var _getLocalStorage = (0, _getLocalStorage2.default)(model.get('id')),
            orderSettings = _getLocalStorage.orderSettings;

        if (orderSettings.isDeferred !== undefined) {
          _this2.set('ticket.isDeferred', orderSettings.isDeferred);
        }

        if (orderSettings.expectedTimeUtc !== undefined) {
          _this2.set('ticket.expectedTimeUtc', orderSettings.expectedTimeUtc);
        }

        if (orderSettings.customerSelectedDay !== undefined) {
          _this2.set('ticket.customerSelectedDay', orderSettings.customerSelectedDay);
        }

        if (orderSettings.discountCode !== undefined) {
          _this2.set('ticket.discountCode', orderSettings.discountCode);
        }

        if (orderSettings.isOrderTypeAsapAndTakeout !== undefined) {
          _this2.set('ticket.isOrderTypeAsapAndTakeout', orderSettings.isOrderTypeAsapAndTakeout);
        }

        if (orderSettings.isOrderTypeAsapAndTakeout) {
          if (model.get('orderTypes').length === 1 && model.get('orderTypes.firstObject.id') === "TAKEOUT") {
            _this2.ticket.getAvailableTimeSlots(model.get('orderTypes.firstObject')).then(function (response) {
              if (response.storeClosures.length) {
                return _this2.ticket.setProperties({
                  isStoreClosureActive: true,
                  activeStoreClosureMessage: response.storeClosures[0].message
                });
              }

              if (response.futureTimeSlots.length > 0) {
                _this2.ticket.setOrderSetting('isOrderTypeAsapAndTakeout', false);
              }
            });
          } else {
            _this2.ticket.setOrderSetting('isOrderTypeAsapAndTakeout', false);
          }
        }

        if (orderSettings.orderTypeGuid !== undefined) {
          var orderType = _this2.store.peekRecord('order-type', orderSettings.orderTypeGuid);

          if (!orderType) {
            _this2.ticket.setProperties({
              orderTypeGuid: undefined,
              storeSettingsChangedError: 'Your chosen order type is no longer available.'
            });

            if (!_this2.isStandaloneRouteTransition) {
              _this2.replaceWith('location.menu.order-settings', {
                queryParams: {
                  previous: _this2.routeName,
                  id: undefined
                }
              });
            }
          } else {
            _this2.ticket.setProperties({
              orderTypeGuid: orderSettings.orderTypeGuid,
              orderType: orderType
            });
          }
        }

        if (orderSettings.dropOffPointRef) {
          if (_this2.get('ticket.orderType.requiresAddress') && _this2.get('ticket.dropOffAddresses')) {
            _this2.set('ticket.isDropOff', true);

            var address = _this2.get('ticket.dropOffAddresses').find(function (dropOffPoint) {
              return dropOffPoint.id === orderSettings.dropOffPointRef;
            });

            if (address) {
              _this2.set('customerService.selectedDeliveryAddress', address);

              _this2.set('customerService.selectedDeliveryAddress.addressLine2', orderSettings.dropOffPointLastMileValue);
            }
          } else {
            _this2.ticket.setOrderSetting('dropOffPointRef', null);

            _this2.ticket.setOrderSetting('dropOffPointLastMileValue', null);
          }
        }

        if (orderSettings.menuScheduleRef !== undefined) {
          // load the schedules (either from the server or just grab off the location)
          // and check if the selected menu schedule exists
          _this2.getSchedules(model).then(function (menuSchedules) {
            var menuSchedule = menuSchedules.findBy('id', orderSettings.menuScheduleRef);

            if (menuSchedule) {
              _this2.ticket.setProperties({
                menuSchedule: menuSchedule,
                menuScheduleRef: orderSettings.menuScheduleRef
              });
            } else if (cartItems.get('length') !== 0) {
              // selected menu schedule no longer available, so clear the cart
              _this2.ticket.clearCart();
            }
          });
        }

        if (_this2.get('ticket.isDeferredFeatureEnabled') && !_this2.get('ticket.dineIn')) {
          if (Object.keys(orderSettings).length === 0 && !_this2.isStandaloneRouteTransition) {
            _this2.replaceWith('location.menu.order-settings');
          }
        } else if (orderSettings.isDeferred) {
          _this2.ticket.setProperties({
            isDeferred: false,
            expectedTimeUtc: undefined,
            storeSettingsChangedError: 'Your chosen order time is no longer available.'
          });

          _this2.ticket.clearCart();

          (0, _clearLocalStorage.default)(model.get('id'));

          if (!_this2.isStandaloneRouteTransition) {
            _this2.replaceWith('location.menu.order-settings', {
              queryParams: {
                previous: _this2.routeName,
                id: undefined
              }
            });
          }
        }

        if (orderSettings.referralGuid !== undefined || orderSettings.referralGuid !== undefined) {
          _this2.set('ticket.referralGuid', orderSettings.referralGuid);

          _this2.set('ticket.referralSource', orderSettings.referralSource);
        }

        _this2.set('isStandaloneRouteTransition', false);
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (this.get('ticket.dineIn') && !model.get('isClosed')) {
        this.getActiveStoreClosures(model).then(function (activeStoreClosure) {
          controller.setProperties(activeStoreClosure);
        });
      }
    },
    actions: {
      // ideally when we upgrade ember we can use the router service
      // for this inside the app-header component itself
      // https://api.emberjs.com/ember/3.28/classes/RouterService/events/routeWillChange?anchor=routeWillChange
      willTransition: function willTransition() {
        // hide the sidebar and dimmer if they're open when we transition routes
        (0, _jquery.default)('.order.column').removeClass('open');
        (0, _jquery.default)('main > .column:first-child').dimmer({
          onHide: function onHide() {}
        }).dimmer('hide');
        return true;
      }
    }
  });

  _exports.default = _default;
});