define("lh-public-olo/templates/components/checkout-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "leg/KYyX",
    "block": "[[[41,[28,[37,1],[[33,2,[\"items\",\"length\"]],[28,[37,3],[[33,2,[\"calculateLoading\"]]],null],[28,[37,3],[[33,2,[\"calculateErrors\"]]],null],[33,2,[\"menuSchedule\",\"isAvailable\"]],[33,4],[28,[37,3],[[33,2,[\"storeSettingsChangedError\"]]],null]],null],[[[41,[33,2,[\"amountRangeViolation\"]],[[[1,\"\\t\\t\"],[10,\"button\"],[14,0,\"ui fluid primary button\"],[12],[1,\"Checkout: \"],[1,[28,[35,5],[[33,2,[\"total\"]]],null]],[13],[1,\"\\n\\t\\t\"],[8,[39,6],null,[[\"@popupTarget\",\"@position\",\"@showOnInsert\"],[[28,[37,7],[\"#\",[33,8],\" > .ui.button\"],null],[28,[37,7],[[52,[33,9,[\"isMobile\"]],\"top\",\"bottom\"],\" right\"],null],false]],[[\"default\"],[[[[1,[33,2,[\"amountRangeViolation\"]]]],[]]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,10],[[33,11,[\"isAuthenticated\"]],[28,[37,12],[[33,2,[\"orderTypeGuid\"]],\"DINE_IN\"],null],[28,[37,3],[[33,2,[\"signInPromptEnabled\"]]],null]],null],[[[1,\"\\t\\t\\t\"],[8,[39,13],[[24,0,\"ui fluid primary button\"]],[[\"@route\",\"@tagName\"],[\"location.checkout\",\"button\"]],[[\"default\"],[[[[1,\"Checkout: \"],[1,[28,[35,5],[[33,2,[\"total\"]]],null]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\\t\"],[8,[39,13],[[24,0,\"ui fluid primary button\"]],[[\"@route\",\"@query\",\"@tagName\"],[\"location.menu.account.entry\",[28,[37,14],null,[[\"nextRoute\"],[\"location.checkout\"]]],\"button\"]],[[\"default\"],[[[[1,\"Checkout: \"],[1,[28,[35,5],[[33,2,[\"total\"]]],null]]],[]]]]],[1,\"\\n\"]],[]]]],[]]]],[]],[[[1,\"\\t\"],[10,\"button\"],[15,0,[29,[\"ui fluid primary button \",[52,[33,2,[\"calculateLoading\"]],\"loading\",\"disabled\"]]]],[15,\"aria-disabled\",[29,[[52,[33,2,[\"calculateLoading\"]],\"false\",\"true\"]]]],[12],[1,\"Checkout: \"],[1,[28,[35,5],[[33,2,[\"total\"]]],null]],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"and\",\"ticket\",\"not\",\"hasNoOffHoursRestrictions\",\"usd\",\"error-popup\",\"concat\",\"elementId\",\"media\",\"or\",\"session\",\"eq\",\"link-to\",\"hash\"]]",
    "moduleName": "lh-public-olo/templates/components/checkout-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});